import { Form } from "../../modules/Form/Form";
import firstBackground from "../../images/firstBackground.jpg";

import s from "./homePage.module.scss";

export const HomePage = () => {
  return (
    <>
      <section className={s.firstPart}>
        <img
          className={s.firstBackground}
          src={firstBackground}
          alt="background img"
        />
        <div className={s.firstBlockText}>
          <h1 className={s.firstBlockTitle}>SEREDINSKA</h1>
          <h2 className={s.firstBlockSecondTitle}>Real Estate agency</h2>
          <a href="#formWrapper" className={s.firstBlocklink}>
            НАДІСЛАТИ ЗАПИТ
          </a>
        </div>
      </section>
      <section className={s.secondPart}>
        <img className={s.secondBackground} alt="First Background" />
        <div id="formWrapper" className={s.formWrapper}>
          <p className={s.formTitle}>Анкета для підбору нерухомості</p>
          <Form />
          <button className={s.submitBtn} type="submit" form="userForm">
            ВІДПРАВИТИ
          </button>
          <p className={s.formPolicyText}>
            Відправляючи анкету, ви даєте згоду на обробку персональних даних та
            погоджуєтесь з політикою конфіденційності
          </p>
        </div>
      </section>
    </>
  );
};
